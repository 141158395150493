<template>
  <div class="Account">
    <div class="banner RuleCenter">
      <div class="title">{{ title }}</div>
      <div class="content" v-html="content"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: '',
      content: ''
    }
  },
  created() {
    this.getContent()
  },
  methods: {
    getContent() {
      this.$axios
          .get(
              `app-api/hiring/news/get?id=5`
          )
          .then((res) => {
            // “0”代表注册成功，其他的均为失败
            if (res.data.code === 0) {
              this.title = res.data.data.newsTitle
              this.content = res.data.data.newsContents
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    }
  }
};
</script>
<style lang="less" scoped>
.Account {
  padding-top: 30px;
  padding-bottom: 30px;
  background: #f7f9fa;
}
.RuleCenter {
  background: #ffffff;
  padding: 50px;
  box-sizing: border-box;
}
.title {
  font-weight: bold;
  color: #000000;
  line-height: 38px;
  font-size: 30px;
  text-align: center;
}
.day {
  font-weight: bold;
  color: #000000;
  line-height: 23px;
  font-size: 20px;
  text-align: center;
  margin-top: 12px;
}
.up-day {
  font-weight: 400;
  color: #000000;
  line-height: 27px;
  font-size: 16px;
  text-align: center;
  margin-top: 12px;
}
.content {
  font-weight: 400;
  color: #000000;
  line-height: 35px;
  font-size: 16px;
  margin-top: 40px;
}
</style>
